.blog {
	width: 22rem;
	border: none;
	box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
	transition: transform .2s;
}

.blog:hover {
	transform: scale(1.05);
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}