/* .privacy-body{
    width: 75% !important;
    margin-top: 60pt;
}

.s1 {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 20pt;
}
p {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 16pt;
  margin: 0pt;
  
}
h4 {
  color: black;
  font-family: Arial, sans-serif !important;
  font-style: normal !important;
  font-weight: bold !important;
  text-decoration: none !important;
  font-size: 20pt !important;
}
.a,
a {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 16pt;
}
h3 {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 22pt !important;
}
h2 {
  color: #374050;
  font-family: "Trebuchet MS", sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 20pt;
}
.s2 {
  color: #374050;
  font-family: "Trebuchet MS", sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 16pt;
}
.s3 {
  color: #374050;
  font-family: "Trebuchet MS", sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 20px;
}
.s4 {
  color: #374050;
  font-family: "Trebuchet MS", sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 16pt;
}
h1 {
  color: #374050;
  font-family: "Trebuchet MS", sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 16pt;
}
li {
  display: block;

}
#l1 {
  padding-left: 0pt;
  counter-reset: c1 1;
}
#l1 > li > *:first-child:before {
  counter-increment: c1;
  content: counter(c1, upper-latin) ". ";
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 16pt;
}
#l1 > li:first-child > *:first-child:before {
  counter-increment: c1 0;
}

#l2 {
  padding-left: 0pt;
  counter-reset: d1 4;
}
#l2 > li > *:first-child:before {
  counter-increment: d1;
  content: counter(d1, decimal) ". ";
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 20pt;
}
#l2 > li:first-child > *:first-child:before {
  counter-increment: d1 0;
}
table,
tbody {
    width: 100%;
    
  vertical-align: top;
  overflow: visible;
}

td{
    font-size: 20px !important;
    width: 25% !important;
} */

/* 
@media screen and (max-width: 768px){
  .privacy-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    


  }

}
@media screen and (max-width: 600px){
  .privacy-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
 
    padding: 30px; 
    max-width: max-content;
  }

}
@media screen and (max-width: 1080px){
  .privacy-table{
  border: 2px solid red;
 }
 .privacy-table2{
  border: 2px solid rgb(10, 205, 17);
 }
 .privacy-table3{
  border: 2px solid rgb(15, 55, 156);
 }
 .privacy-table4{
  border: 2px solid rgb(44, 1, 29);
 }
 .table-container td p{
 
  font-size: 18px;

 }
}  */

@media(max-width:620px){
	th{
		font-size: 14px;
		line-height: 20px;
	}
	td{
		font-size: 12px;
		line-height: 20px;

	}
	.privacy-table{
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}
  .text-lg{
    margin-top: 10px;
  }
  .text-lg h1{
    font-size: 18px;
  }
  .text-lg p {
    font-size: 16px;
  }

}

@media(max-width:420px){
	th{
		font-size: 12px;
	}
	td{
		font-size: 10px;
		line-height: 15px;
	}
	.privacy-table{
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}
  .text-lg h1{
    font-size: 14px;
  }
  .text-lg p {
    font-size: 12px;
  }

}