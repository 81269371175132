.contact-us {
  height: 80vh;
  margin-top: 80px;
}

.heading {
  background-color: rgb(127 1 252);
}

.info {
  align-items: center;
  justify-content: center;
  margin-top: 200px;
}
