.aboutOffer {
    background: white;
    font-size: 40px;
    font-weight: 800;
    color: #6D12FF;
    border-radius: 20px;
    padding: 10px 30px;
    width: 900px;
    text-align: center;
    margin-bottom: 110px
}
.purpledot{
    margin-bottom:4px; 
}

.about1Img {
    height: 550px;
    width: 800px;
}

.text1 {
    width: 600px;
    margin-right: 100px;
}

.about2Img {
    height: 560px;
    width: 800px;
}

.text2 {
    width: 600px;
    margin-left: 100px;
}


@media screen and (max-width:600px) {
    .aboutOffer{
        font-size: 28px;
    }
    .purpledot{
        width: 12px;
        height: 12px;
    }
    .about1Img {
        height: 260px;
        width: 370px;
    }

    .text1 {
        width: 90vw;
        margin-top: 30px;
        margin-right: auto;
        text-align: center;
        margin-left: auto;
    }

    .about2ImgParent{
        margin-left: auto;
    }
    .about2Img {
        height: 265px;
        width: 370px;
        order: 0;
    }

    .text2 {
        width: 90vw;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        margin-top: 30px;
        order: 1;
    }
}