.Link a{
color:white;
text-decoration: none;
font-size: 18px;
/* margin-left:25px; */
}
.Link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

}
.Icons{
    width:33vw;
}
.Icons img{
    margin-right: 15px;
}
.offer{
    font-size:30px;
    font-weight:700;
}
.logoFooter{
    height:90px;
    width:190px; 
}
@media screen and (max-width:1080px) {
  
    .Link a{
        margin-left: 0;
        font-size: 14px;
    }
}
@media screen and (max-width:880px) {
  
    .Link a{
        margin-left: 0;
        font-size: 12px;
    }
    .Logo img{

        width: 90%;
    }
}
@media screen and (max-width:780px) {
  
    .Link a{
        margin-left: 0;
        font-size: 10px;
    }
}
@media screen and (max-width:700px) {
    .Link{
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    .Link a{
        margin-left: 0;
        font-size: 13px;
    }
    .Icons{
        display: flex;
        flex-direction: column;
        gap:15px;
        margin-right: -20px;
    }
    .Logo{
        margin-right: 20px;
    }
    .logoFooter{
        margin-left: 40px;
        height: 50px;
        width: 100px;
    }
    .offer{
        width: 160px;
        margin-top: 10px;
        margin-left: 10px;
        font-size: 15px;
    }
    .dotimg{
        width: 7px;
        height: 7px;
    }
    .copyright p{
        font-size: 16px;
    }
}