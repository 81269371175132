.navbarWrap{
    background:#FFFFFF;
    padding-left: 55px;
    padding-right: 40px;
}
.Li{
    font-size: 18px;
    margin-right: 20px;
    font-weight: 700;
    margin-top: 5px;
}
.Li:hover{
    color:#6D12FF;
}
.Li:focus{
    color:#6D12FF;
    font-weight: 700;
}
.Li:active{
    color:#6D12FF;
    font-weight: 700;
}
.Btn1{
    background:#6D12FF;
    border-radius:5px;
    color:#FFFFFF;
    font-size:12px;
    font-weight:600;
    padding:12px 26px;
    border:2px solid #6D12FF;
    margin-top: -5px;
}
.Btn1:hover{
    transition: .3s ease-in-out;
    background: white;
    color:#6D12FF;
}

@media screen and (max-width:768px){
    .navbarWrap{
       padding-left: 15px;
       padding-right: 10px;

    }
    .logonavbar{
        height: 60px;
        width: 110px;
    }
    .Li{
        margin-right: 0;
        margin-left: 10px;
        font-weight: 500;
    }
    .Btn1{
        margin-left: 10px;
    }
}
